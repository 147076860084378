@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

$gutter: 1rem;

@import "./assets/layout/styles/layout/layout.scss";

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import "./assets/demo/styles/flags/flags.css";
@import "./assets/demo/styles/badges.scss";
@import "./assets/demo/styles/code.scss";

// @import "~@ng-select/ng-select/themes/default.theme.css";

* {
  font: inherit;
  font-family: Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

img,
video,
svg {
  display: block;
  height: auto;
  max-width: 100%;
}

body {
  min-height: 100dvh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
blockquote {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

p {
  text-wrap: pretty;
}

.fc-toolbar-title,
.fc-col-header-cell-cushion {
  text-transform: capitalize;
}

.hov-pointer {
  cursor: pointer;
}

.bg-table-info {
  background-color: #5f7adb !important;
  color: #fff !important;
}

.img-dropdown {
  width: 20px;
}

.d {
  z-index: 9999 !important;
}

.custom-table {
  white-space: nowrap;
}

.label-img {
  text-align: center;
  padding: 3px;
  margin-right: 20px;
  transition: background-color 1s, color 1s;
  -webkit-transition: background-color 1s, color 1s;
  -moz-transition: background-color 1s, color 1s;
  -ms-transition: background-color 1s, color 1s;
  -o-transition: background-color 1s, color 1s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.contenedor-imagen {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: 400ms;
}

.contenedor-imagen>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenedor-imagen:hover>img {
  filter: grayscale(100%) blur(2px);
}

.leyenda {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.contenedor-imagen:hover .leyenda {
  opacity: 1;
}

.contenedor-imagen:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
}

.border-left {
  border-left: 4px solid var(--primary-500);
  border-bottom: 4px solid var(--primary-500);
}

.borde-shadow {
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}

canvas {
  background-color: white;

  border-radius: 7px;
  box-shadow:
    0 0px 3.3px rgba(0, 0, 0, 0.017),
    0 0px 7.9px rgba(0, 0, 0, 0.024),
    0 0px 14.9px rgba(0, 0, 0, 0.03),
    0 0px 26.6px rgba(0, 0, 0, 0.036),
    0 0px 49.7px rgba(0, 0, 0, 0.043),
    0 0px 119px rgba(0, 0, 0, 0.06);

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.p-breadcrumb {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
  margin-bottom: 1rem !important;
}

.p-breadcrumb .p-breadcrumb-list {
  margin: 0 !important;
}

.p-breadcrumb .p-breadcrumb-list .p-breadcrumb-item a {
  color: #333 !important;
  text-decoration: none !important;
}

.p-breadcrumb .p-breadcrumb-list .p-breadcrumb-item a:hover {
  color: #007bff !important;
}

.th-inside {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.125rem;
  font-weight: 600;
}

.fc .fc-button {
  background-color: #4c63b6 !important;
  color: white !important;
  border-color: #4c63b6 !important;
}

.fc .fc-button:hover {
  background-color: #3b4e96 !important;
}

.fc .fc-button:active {
  background-color: #2d3d73 !important;
}

.fc .fc-button-active {
  background-color: #2d3d73 !important;
}

::ng-deep .p-paginator .p-paginator-page,
::ng-deep .p-paginator .p-paginator-prev,
::ng-deep .p-paginator .p-paginator-next {
  min-width: 3rem;
}

.p-menuitem-text {
  font-size: 0.875rem !important;
  font-weight: bold !important;
}

.p-menuitem-icon {
  font-size: 0.875rem !important;
  font-weight: bold !important;
}

.p-dropdown-item {
  padding: 0.75rem 1rem !important;
}

.p-autocomplete-item {
  padding: 0.75rem 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}
